<template>
  <div>
    <div class="plTitle">退款订单</div>
    <div class="plTxt dis-cent">
      <div class="txt">订单号：{{ info.ordernum }}</div>
      <div class="txt">{{ info.create_time }}</div>
    </div>
    <div class="content">
      <div class="cont" v-for="(item, index) in list">
        <div class="cpBox">
          <div class="img">
            <img
              v-if="item.part"
              :src="$imgUrl + item.part.resources[0].path_name"
              alt=""
            />
          </div>
          <div class="name">{{ item.productname }}</div>
        </div>
        <div class="contBox">
          <div class="box">
            <div class="name">退款类型</div>
            <div class="typeBox">
              <div
                class="type"
                v-for="(chr, chi) in item.typeList"
                @click="typeClick(index, chi)"
              >
                <div
                  class="yuan dis-cent"
                  :class="item.typeOn == chi ? 'on' : ''"
                >
                  <div class="shi"></div>
                </div>
                <div class="txt">{{ chr }}</div>
              </div>
            </div>
          </div>
          <div class="box" v-if="item.typeOn != 0">
            <div class="name">退货数量</div>
            <div class="inpBox">
              <input
                type="text"
                :placeholder="'请输入退款数量（不可大于' + item.num + '件）'"
                v-model="item.shuliang"
                @blur="numhandleBlur(index)"
              />
            </div>
          </div>
          <div class="box">
            <div class="name">退款金额</div>
            <div class="inpBox">
              <input
                type="text"
                :placeholder="'请输入退款金额（不可大于' + money + '元）'"
                v-model="item.jine"
                @blur="moneyhandleBlur(index)"
              />
            </div>
          </div>
          <div class="box">
            <div class="name" style="margin-top: 8px">退款原因</div>
            <div class="funBox">
              <div class="textVal">
                <textarea
                  name=""
                  id=""
                  placeholder="填写退款原因~"
                  v-model="item.xinde"
                ></textarea>
              </div>
              <div class="upImg">
                <div class="imgs">
                  <div
                    class="img"
                    @click="uploadFile(index)"
                    v-if="item.imgList.length < 9"
                  >
                    <div class="imgBox iconfont icon-camera_fill"></div>
                  </div>
                  <div class="img" v-for="(chr, chi) in item.imgList">
                    <img :src="$imgUrl + chr.path_name" />
                    <div
                      class="iconfont icon-close close"
                      @click="imgDelClick(chi, index)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="saveCont dis-cent">
      <div class="saveBtn dis-cent" @click="plClick">确定退款</div>
    </div>
    <input
      type="file"
      ref="fileInput"
      @change="onFileChange"
      style="display: none"
    />
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      list: [
        {
          name: "1",
          imgList: [],
          typeOn: null,
          typeList: ["仅退款", "仅退款", "仅退款"],
        },
      ],
      imgI: 0,
      money: 0,
      info: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.chrid = this.$route.query.chrid;
    this.get();
    this.cpget();
  },
  methods: {
    get() {
      this.$get("/web/order_get", {
        id: this.id,
      }).then((res) => {
        console.log(res);
        this.info = res.data;
      });
    },
    cpget() {
      this.$get("/web/car_get", {
        id: this.chrid,
      }).then((res) => {
        this.money = res.data.price * res.data.num;
        res.data.typeOn = 100;
        res.data.imgList = [];
        res.data.typeList = ["仅退款", "退货退款", "换货"];
        let list = [];
        this.$forceUpdate();
        list.push(res.data);
        this.list = list;
        console.log(list);
        this.info = res.data;
      });
    },
    numhandleBlur(i) {
      if (isNaN(this.list[i].shuliang)) {
        this.$message.error("请输入数字");
        this.list[i].shuliang = "";
      } else if (this.list[i].shuliang > this.list[i].num) {
        this.$message.error("品数量不能大于总件数");
        this.list[i].shuliang = "";
      } else if (this.list[i].shuliang <= 0) {
        this.$message.error("产品数量不能小0件");
        this.list[i].shuliang = "";
      }
    },
    moneyhandleBlur(i) {
      if (isNaN(this.list[i].jine)) {
        this.$message.error("请输入数字");
        this.list[i].jine = "";
      } else if (this.list[i].jine > this.money) {
        this.$message.error("产品金额不能大于实付款");
        this.list[i].jine = "";
      } else if (this.list[i].jine <= 0) {
        this.$message.error("产品金额不能小0元");
        this.list[i].jine = "";
      }
    },
    plClick() {
      let data = [];
      for (let i = 0; i < this.list.length; i++) {
        const e = this.list[i];
        let imgId = [];
        for (let j = 0; j < e.imgList.length; j++) {
          const ej = e.imgList[j];
          imgId.push(ej.id);
        }
        data.push({
          remark: e.xinde,
          refuse_price: Number(e.jine),
          amount: Number(e.shuliang),
          remark_img: imgId.join(","),
          part_id: Number(e.part_id),
          order_id: Number(this.id),
          car_id: e.id,
          way: e.typeOn + 1,
        });
      }
      for (let i = 0; i < data.length; i++) {
        const e = data[i];
        if (e.way == 101) {
          this.$message.warning("请选择退款类型");
          return;
        }
        if (e.way != 1) {
          if (!e.amount) {
            this.$message.warning("请填写退款数量");
            return;
          }
        }
        if (!e.refuse_price) {
          this.$message.warning("请填写退款金额");
          return;
        }
        if (!e.remark) {
          this.$message.warning("请填写退款原因");
          return;
        }
      }
      data = data[0];
      data = JSON.stringify(data);
      console.log(data);
      this.$post("/web/refund_add", {
        data: data,
      }).then((res) => {
        console.log(res);
        this.visible = false;
        if (res.data.status) {
          this.$message.success("提交成功");
          this.$router.replace({
            path: "/orderCont",
            query: {
              id: this.id,
            },
          });
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    typeClick(i, chi) {
      this.list[i].typeOn = chi;
    },
    imgDelClick(i, gaoI) {
      this.list[gaoI].imgList.splice(i, 1);
    },
    async uploadFile(i) {
      this.imgI = i;
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      var formData = new FormData();
      console.log(e.target.files);
      formData.append("file", e.target.files[0]);
      formData.append("where", "product");
      this.$uploadfile("/common/fileupload", formData).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.list[this.imgI].imgList.push(res.data.data);
        } else {
          this.$message.error("网络繁忙,请稍后再试~");
        }
      });
    },
    mouseover(chi, i) {
      this.list[i].on = chi + 1;
    },
    mouseout(chi, i) {
      if (!this.list[i].qd) {
        this.list[i].on = 0;
      } else {
        this.list[i].on = this.list[i].qd;
      }
    },
    cpClick(chi, i) {
      this.list[i].qd = chi + 1;
      this.list[i].on = chi + 1;
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.plTitle {
  font-weight: 900;
  font-size: 16px;
  text-align: center;
  margin-top: 25px;
}

.plTxt {
  margin-top: 5px;
  .txt {
    margin-right: 20px;
  }
  .txt:last-child {
    margin-right: 0;
  }
}

.content {
  width: 1440px;
  margin: auto;
  background-color: white;
  border-radius: 5px;
  margin-top: 30px;
  .cont {
    border-bottom: 1px solid #ebebeb;
    display: flex;
    .cpBox {
      width: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-right: 1px solid #ebebeb;
      padding: 10px 0;
      .img {
        width: 150px;
      }
      .name {
        margin-top: 10px;
        color: #666;
      }
    }
    .contBox {
      width: calc(100% - 40px);
      box-sizing: border-box;
      padding: 20px 30px;
      .box {
        display: flex;
        margin-bottom: 20px;
        .name {
          width: 150px;
          height: 30px;
          line-height: 30px;
        }
        .inpBox {
          width: calc(100% - 150px);
          height: 40px;
          border-radius: 5px;
          overflow: hidden;
          box-sizing: border-box;
          border: 1px solid #ebebeb;
          padding-left: 12px;
          input {
            width: 100%;
            height: 100%;
          }
        }
        .typeBox {
          display: flex;
          align-items: center;
          height: 30px;
          .type {
            display: flex;
            align-items: center;
            color: #999;
            margin-right: 15px;
            cursor: pointer;
            .yuan {
              border-radius: 50%;
              border: 1px solid #c9c9c9;
              width: 16px;
              margin-right: 5px;
              height: 16px;
              margin-right: 5px;
              margin-top: 1px;
              .shi {
                width: 8px;
                height: 8px;
                background-color: #fff;
                border-radius: 50%;
              }
            }
            .on {
              background-color: #e23;
              border: 1px solid #e23;
            }
          }
        }
        .funBox {
          width: calc(100% - 150px);
          .textVal {
            width: 100%;
            border-radius: 5px;
            border: 1px solid #ebebeb;
            overflow: hidden;
            box-sizing: border-box;
            textarea {
              border: none;
              width: 100%;
              height: 150px;
              padding: 10px 15px;
            }
          }
          .upImg {
            margin-top: 10px;

            .text {
              margin-left: 20px;
              font-size: 12px;
            }

            .txt {
              margin-left: 5px;
              font-size: 12px;
              color: #999;
            }

            .imgs {
              display: flex;
              margin-top: 15px;
              flex-wrap: wrap;

              .img {
                width: calc(10% - 9px);
                margin-right: 10px;
                padding-top: calc(10% - 9px);
                position: relative;
                overflow: unset;
                .imgBox {
                  width: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  border-radius: 5px;
                  box-shadow: 0 0 4px 2px #ebebeb;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #9c9c9c;
                  font-size: 26px;
                  cursor: pointer;
                }
                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-radius: 5px;
                }

                .close {
                  position: absolute;
                  top: -3px;
                  right: -3px;
                  background-color: #b3b3b3;
                  border-radius: 50%;
                  width: 15px;
                  height: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 10px;
                  color: #fff;
                  cursor: pointer;
                }
              }

              .img:nth-child(5n) {
                margin-right: 0;
              }
            }
          }
        }
      }
      .box:last-child {
        margin-bottom: 10px;
      }
    }
  }
  .cont:last-child {
    border-bottom: none;
  }
}

.saveCont {
  width: 1440px;
  margin: auto;
  background-color: white;
  border-radius: 5px;
  height: 120px;
  margin-top: 25px;
  .saveBtn {
    background-color: #e23;
    color: #fff;
    width: 250px;
    height: 55px;
    cursor: pointer;
    border-radius: 5px;
  }
}
</style>
